.link {
  cursor: pointer;
}

.edge {
  user-select: none;
}

.edge:hover {
  font-weight: bolder;
  stroke-width: 3px;
}

.deprecated path,
.deprecated polygon {
  stroke: darkred;
  stroke-dasharray: 6px;
  stroke-width: 3px;
}
